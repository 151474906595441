$WC-Blue: #0f345a;
$WC-Lime-green: #b3c035;
$WC-Teal: #0F8496;
$WC-Body-text: #474C55;
$WC-Headings: #0033a0;
$WC-Headings-2: #0033A0;
$WC-red-underline: #FF4E00;
$WC-bg: #F5F5F5;





/* ######### Start Type Face ######### */
/* Top Menu */
main{
    h1,h2,h3,h4,h5,h6{
        margin-bottom: 1rem;
        
    }
    h2,h3,h4,h5,h6{
        line-height: 1.5em;
    }
    h1, h2, h3{
        font-family: 'Gotham-Medium';
    }
    h4,h5{
        font-family: 'Gotham-Bold';
    }
    h2,h3,h4,h5,h6{
        color: $WC-Headings;
    }
    h1,h2.section{
        color: $WC-Headings-2;
        // font-size:2.33rem;
        font-size: 2em;
        margin-bottom: 0.5em !important;
        margin-top: 1.3em;

    }
    h1.main{
        font-size:3.5rem;
        font-family: 'Gotham-Medium';
        color: $WC-Headings-2;
    }
    h1.page-header{
        color: #0F8496;
        font-size: 3em;
        font-family: 'Gotham-Light';
        text-align: center;
        margin-top: 1.5em !important;
        margin-bottom: 1em !important;
        width: 80%;
        margin: 0 auto;
    }
    h1.section, h2.section{
        text-transform: capitalize;
        font-family: 'Gotham-Bold';
        font-size: 3.5em !important;
        display:block;
    }
    h2.sectoin
    h2:not(.section){
        font-size:1.6rem !important;
        margin-bottom: 0.5em;
        margin-top: 1em;
    }
    h3{
        font-size:1.45rem;
    }
    h4{
        font-size: 1.22rem;
    }
    h5{
        font-size:1rem;
    }
    p{
        font-size:1.1em;
        // font-size:1.1rem;
        font-family: 'Gotham-Book';
        color: $WC-Body-text;
        margin-bottom:1rem;
    }
    p.date{
        margin-bottom:2rem;
    }
    ul{
        padding-left: 1rem;
        li{
            width:90%;
            margin-bottom:0.8rem;
            font-size: 1.1em;
            font-family: 'Gotham-Book';
        }
        li:last-of-type{
            margin-bottom:1.6rem;
        }
    }
    ul.pagination{
        margin-top: 5em;
        margin-bottom: 0em;
        li{
            text-align: center;
            .page-link{
                color: #0f345a !important;
                background-color: #fff;
                border-color: #cacaca;
                transition: ease-in-out .3s;
                z-index: 1;
            }
            .page-link:hover{
                background-color: $WC-Teal;
                border-color: #cacaca;
                color:#fff !important;
            }
        }
        li.page-item.active{
            .page-link{
                background-color: $WC-Lime-green;
                border-color: #cacaca;
                color:#fff !important;
                transition: ease-in-out .3s;
                z-index: 1;
            }
            .page-link:hover{
                background-color:$WC-Teal;
            }
        }
    }

    blockquote{
        padding: 2em;
        text-align: center;
        border-top: 2px solid #e4e4e4;
        border-bottom: 2px solid #e4e4e4;
        margin-bottom:4em;
        margin-top:3em;
        font-size: 1.1em;
        p{
            font-size: 1.1em;
            span{
                font-size:1.9rem;
            }
            em{
                font-size: 1.1rem;
            }
        }
    }
    p.credit{
        font-size: 0.8rem;
        padding-bottom:2rem;
    }
    
/* ######### Start Anitmated LINK ######### */
    a {margin:0;  }

    .richContent{
        p:last-of-type{
            margin-bottom:2em;
        }
    }
}

.topMenuItem{
    font-family: 'Gotham-Book';
    /* font-size: 1.33rem; 16pt */
    font-size: 1rem;
    color: #474c55 !important;
    letter-spacing: 0.5px;
}

h1.topNav{
    font-family: 'Gotham-Bold';
}
.topNavDescription{
    font-family: 'Gotham-Book';
    font-size: 1em;
    
}
.grandChildLink{
    /* font-size:1rem; */
    font-size: 1em;
    font-family: 'Gotham-Medium';
}
.wcLinkBold{
    font-family: 'Gotham-Bold';
    font-size: 1rem !important;
}

.wcLinkBigBold{
    font-family: 'Gotham-Bold';
    font-size: 1.2rem !important;
    letter-spacing:0.25px;
}
.menuHighlight{
    font-family: 'Gotham-Bold' !important;
}
h3.catchyTagLine{
    font-size: 1.75rem;
    font-family: 'Gotham-Book';
}
.searchWrapper{
    color:$WC-Blue;
    input{
        font-family: 'Gotham-Book';
        font-size:2em;
    }

}


main{ 
    .pageBanner{

        .CTA1 {

          h1{
            font-size: 3em;
            
            p{
                font-size: inherit;
                color: #fff;
                text-align: center;
            }
            
            /* font-family: 'Gotham-Bold';*/
         }
      }
    }
}

main .introWrapper p{
    font-family: 'Gotham-Book';

}
main .introWrapper a{
    font-family: 'Gotham-Bold';
}
main .contentWrapper .solutionsContainer a{
    font-family: 'Gotham-Bold';
}
main .contentWrapper .solutionsContainer p,main .contentWrapper .solutionsBlock .solutionsContainerInfo p{
    font-family: 'Gotham-Book';
}
main .contentWrapper .solutionsBlock .solutionsContainerInfo h1,main .contentWrapper .solutionsBlock .solutionsContainerInfo h2{ 
    font-family: 'Gotham-Bold';
}
main .contentWrapper .subscribeBlock h1{
    font-family: 'Gotham-Medium';
}
main .toolBox h1{
    font-family: 'Gotham-Medium';
}
main .toolBox h2{
    font-family: 'Gotham-Medium';
}
.wcInput{
    font-family: 'Gotham-Book';
}
main .testimonials .content .testimonialsSlider .slick-list.draggable p {
   font-family: 'Gotham-Medium';
   font-size: 1.8em;
   text-align:center;
   color: #fff;
}
.hr-text {
    font-family: 'Gotham-Bold';
  }

  main{
    .successStories{
        h1{
            font-family: 'Gotham-Bold';
        }
        .content{
            
            .storySlider{
                font-family: 'Gotham-Book';
                h1{
                    font-size: 1.4em;
                    color:#000;
                    font-family: 'Gotham-Bold';
                }
                p{
                    font-family: 'Gotham-Book';
                    font-size:1.2em;
                }
            }
        }
    }
}

main{
    .connect-expert{
        h1{
            font-size: 4.5em;
            font-family: 'Gotham-Bold';
        }
    }
}

main{
    
    .blog{

        .container{


            .row{
                [class^="col"]{

                    p:first-of-type{

                    }
                }
            }
            p{
                font-family: 'Gotham-Book';
                font-size:1em;
            }
            p:first-of-type{
                font-family: 'Gotham-Bold';
                font-size:1em;
            }
            .portraitContainer{
                .postedBy{
                    p{
                        font-family: 'Gotham-Bold';
                        font-size:0.9em;
                    }
                }
            }
            .blogContent{
                p{
                  font-family: 'Gotham-Book';
                  font-size:1.2em;
                  }
                  p:first-of-type{
                      font-family: 'Gotham-Book';
                      font-size:1em;
                  }  
              }
        }
        h1{
            font-size: 4em;
            font-family: 'Gotham-Bold';
            color: $WC-Blue;
        }
        h2{
            font-size: 1.4em;
            color: $WC-Headings;
            font-family: 'Gotham-Bold';
            line-height: 1.3em;
            margin-top: 0.8em;
            margin-bottom: 1em;
        }
        h3{
            font-family: 'Gotham-Book';
            font-size:1.2em;
        }

    }
}


footer{
    .menu{
        h1{
            font-family: 'Gotham-Bold';
        }
        a{
            font-family: 'Gotham-Book';
        }
    }
    .copyrightWrapper{

        p{
            font-family: 'Gotham-Book';
            font-size:0.8em;
            color:$WC-bg;
            text-transform: uppercase;
        }

    }
    .wcInfo{
        h1{
            font-family: 'Gotham-Bold';
            font-size: 1.5em;
        }
        h2{
            font-family: 'Gotham-Book';
            font-size: 1.3em;
        }
    }   
}


/* ######### End Typeface ######### */